import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

import {DevToolbox} from './dev-toolbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,
        FormsModule,
    ],
    declarations: [
        DevToolbox,
    ],
    exports: [
        DevToolbox,
    ],
})
export class DevToolboxModule {}
