import {NgModule, ErrorHandler, Injectable} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {JwtModule} from '@auth0/angular-jwt';
import {HttpClientModule} from '@angular/common/http';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatInputModule} from '@angular/material/input';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {MainModule} from './main/main.module';
import {DirectivesModule} from '../components/directives.module';

import {constants} from './app.constants';
import * as Sentry from '@sentry/browser';
import {AUTH_TOKEN_KEY, AuthService} from '../components/auth/auth.service';
import {AdminComponent} from './admin/admin.component';
import {DevToolboxModule} from '../components/dev-toolbox/dev-toolbox.module';

if (process.env.NODE_ENV === 'production') { // eslint-disable-line no-undef
    Sentry.init({
        dsn: constants.sentry.publicDsn,
    });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    handleError(error) {
        const eventId = Sentry.captureException(error.originalError || error);
        Sentry.showReportDialog({eventId});
    }
}

export function tokenGetter() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
}

const appRoutes: Routes = [
    {
        path: '',
        children: [{
            path: '',
            pathMatch: 'full',
            redirectTo: '/home',
        }, {
            path: 'account',
            loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
        }, {
            path: 'admin',
            component: AdminComponent,
            loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        }, {
            path: 'blog',
            loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
        }, {
            path: 'galleries',
            loadChildren: () => import('./galleries/galleries.module').then((m) => m.GalleriesModule),
        }, {
            path: 'projects',
            loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
        }, {
            path: 'settings',
            loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
        }, {
            path: 'user',
            loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
        }],
    },
];

@NgModule({
    providers: [{provide: ErrorHandler, useClass: SentryErrorHandler}],
    imports: [
        BrowserModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: ['andrewk.me'],
            },
        }),
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, {enableTracing: true}),
        MainModule,
        DirectivesModule,
        DevToolboxModule,
    ],
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private readonly authService: AuthService) {
        this.authService.checkToken();
    }
}
