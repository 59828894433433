import {Component, Inject, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

const DARK_THEME_NAME = 'dark-theme';
const DARK_MODE_LOCALSTORE_KEY = 'darkMode';

@Component({
    selector: 'app',
    template: `<navbar></navbar>
    <router-outlet></router-outlet>
    <footer></footer>
    <ng-container *ngIf="isDevelopment">
        <dev-toolbox></dev-toolbox>
    </ng-container>`,
})
export class AppComponent {
    isDevelopment = process.env.NODE_ENV === 'development';

    constructor(@Inject(DOCUMENT) private document: Document,
                private renderer: Renderer2) {
        const darkModeLocal = localStorage.getItem(DARK_MODE_LOCALSTORE_KEY);

        if (darkModeLocal === '1') {
            renderer.addClass(document.body, DARK_THEME_NAME);
        } else {
            renderer.removeClass(document.body, DARK_THEME_NAME);
        }
    }
}
