import {Component, Inject, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {FormControl} from '@angular/forms';

const DARK_THEME_NAME = 'dark-theme';
const DARK_MODE_LOCALSTORE_KEY = 'darkMode';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.html',
    styleUrls: ['./navbar.scss'],
})
export class NavbarComponent {
    isCollapsed = true;
    menu = [{
        title: 'Home',
        link: '/home',
    }, {
        title: 'Résumé',
        link: '/resume',
    }, {
        title: 'Projects',
        link: '/projects',
    }, {
        title: 'Photography',
        link: '/galleries',
    }, {
        title: 'Blog',
        link: '/blog',
    }];

    themeToggleControl = new FormControl(false);

    currentUserObservable = this.authService.currentUserObservable;
    isAdminObservable = this.authService.isAdminObservable;

    constructor(private readonly authService: AuthService,
                private readonly router: Router,
                @Inject(DOCUMENT) private document: Document,
                private renderer: Renderer2,
    ) {
        // TODO: This should probably go into a service
        const darkModeLocal = localStorage.getItem(DARK_MODE_LOCALSTORE_KEY);

        if (darkModeLocal === '1') {
            this.themeToggleControl.setValue(true);
        }

        this.themeToggleControl.valueChanges.subscribe((darkMode) => {
            if (darkMode) {
                renderer.addClass(document.body, DARK_THEME_NAME);
                localStorage.setItem(DARK_MODE_LOCALSTORE_KEY, '1');
            } else {
                renderer.removeClass(document.body, DARK_THEME_NAME);
                localStorage.setItem(DARK_MODE_LOCALSTORE_KEY, '0');
            }
        });
    }

    private authLogout() {
        return this.authService.logout();
    }

    logout() {
        const promise = this.authLogout();
        this.router.navigateByUrl('/home');
        return promise;
    }
}
