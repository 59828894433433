<mat-toolbar color="primary" class="mat-elevation-z6">
    <a class="logo-portrait" routerLink="home" aria-label="Go to the home page">
        <img class="img-circle" src="assets/images/portrait_2014.jpg" alt="AK"/>
    </a>
    <ul class="nav">
        <li *ngFor="let item of menu" routerLinkActive="active">
            <a routerLink="{{item.link}}" [attr.aria-label]="item.title">{{item.title}}</a>
        </li>
        <li [hidden]="!(isAdminObservable | async)" routerLinkActive="active">
            <a routerLink="/admin" aria-label="Admin">Admin</a>
        </li>
    </ul>
    <span class="example-spacer"></span>
    <ul class="nav navbar-nav navbar-right">
        <li class="theme-toggle">
            <mat-slide-toggle [formControl]="themeToggleControl" class="mr-8" labelPosition="before">
                <mat-icon>{{themeToggleControl.value ? 'dark_mode' : 'light_mode'}}</mat-icon>
            </mat-slide-toggle>
        </li>
        <!--<li [hidden]="!isLoggedIn()" routerLinkActive="active"><a href="/signup">Sign up</a></li>-->
        <li [hidden]="currentUserObservable | async" routerLinkActive="active">
            <a routerLink="/account/login" aria-label="Login">Log In</a>
        </li>
        <li *ngIf="currentUserObservable | async" style="margin-left: 5px; margin-right: 5px;">
            <a routerLink="/user/me" style="padding: 0"><!--[uiParams]="{id: getCurrentUser()._id}"-->
                <span class="user-image" style.background-image="url(/api/upload/{{(currentUserObservable | async).imageId}}.jpg)"></span>
            </a>
        </li>
        <li [hidden]="!(currentUserObservable | async)" routerLinkActive="active">
            <a routerLink="/settings" aria-label="Settings">
                <mat-icon class="example-icon" aria-hidden="false" aria-label="Settings">settings_applications</mat-icon>
            </a>
        </li>
        <li [hidden]="!(currentUserObservable | async)" routerLinkActive="active">
            <a (click)="logout()" aria-label="Logout">Log Out</a>
        </li>
    </ul>
<!--    <span>Third Line</span>-->
<!--    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example delete icon">delete</mat-icon>-->
</mat-toolbar>
