import {Component} from '@angular/core';
import {DevToolboxService} from './dev-toolbox.service';
import {ToolboxConfig} from '../../../server/api/toolbox/util';

@Component({
    selector: 'dev-toolbox',
    templateUrl: './dev-toolbox.ng.html',
    styleUrls: ['./dev-toolbox.scss'],
    providers: [DevToolboxService],
})
export class DevToolbox {
    isExpanded = false;
    config: ToolboxConfig;

    constructor(private readonly devToolboxService: DevToolboxService) {}

    async ngOnInit() {
        this.config = await this.devToolboxService.getConfig();
    }

    async onChange() {
        await this.devToolboxService.setConfig(this.config);
    }
}
