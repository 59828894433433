<mat-sidenav-container>
    <mat-sidenav #sidenav (openedStart)="mybutton.focus()" [opened]="false">
        <mat-toolbar color="primary">
            <button mat-icon-button #mybutton (click)="sidenav.close()">
                <mat-icon>menu</mat-icon>
            </button>
            <span>Admin</span>
        </mat-toolbar>

        <ul class="sidenav-menu">
            <li class="" *ngFor="let section of sections">
                <button mat-button [routerLink]="section.link" (click)="sidenav.close()">
                    <mat-icon>{{section.icon}}</mat-icon>
                    <span>{{section.title}}</span>
                </button>
            </li>
        </ul>
    </mat-sidenav>

    <button mat-icon-button (click)="sidenav.open()">
        <mat-icon>menu</mat-icon>
    </button>

    <div class="admin-outlet-container">
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>
