<div>
    <button mat-fab
        [hidden]="isExpanded"
        (click)="isExpanded = true">
        <mat-icon>bug_report</mat-icon>
    </button>
    <div [hidden]="!isExpanded" class="toolbox mat-elevation-z2">
        <button mat-icon-button (click)="isExpanded = false" class="close-button"><mat-icon>close</mat-icon></button>

        <span>skipSeed: </span>
        <mat-slide-toggle [(ngModel)]="config.skipSeed" (ngModelChange)="onChange()"></mat-slide-toggle>
    </div>
</div>
