<div class="styled-home-text">
    <div [hidden]="activeText !== 0">
        <svg viewBox="0 0 600 100" (click)="changeText()" id="bg-img-text">
            <symbol id="s-text">
                <text text-anchor="middle" x="50%" y="50%" dy=".35em" class="text">
                    Andrew Koroluk
                </text>
            </symbol>

            <mask id="masked-text" maskunits="userSpaceOnUse" maskcontentunits="userSpaceOnUse">
                <use xlink:href="#s-text" class="text-mask"></use>
            </mask>

            <g mask="url(#masked-text)">
                <image xlink:href="../assets/images/low_poly_texture.jpg" width="1500" height="350" x="-75%"
                       y="-50%"></image>
            </g>
        </svg>
    </div>
    <div [hidden]="activeText !== 1">
        <svg viewBox="0 0 600 100" (click)="changeText()" id="dashed-stroke-text">
            <!-- Symbol -->
            <symbol id="s-text">
                <text text-anchor="middle" x="50%" y="50%" dy=".35em">
                    Andrew Koroluk
                </text>
            </symbol>

            <!-- Duplicate symbols -->
            <use xlink:href="#s-text" class="dashed-stroke-text"></use>
            <use xlink:href="#s-text" class="dashed-stroke-text"></use>
            <use xlink:href="#s-text" class="dashed-stroke-text"></use>
            <use xlink:href="#s-text" class="dashed-stroke-text"></use>
            <use xlink:href="#s-text" class="dashed-stroke-text"></use>
        </svg>
    </div>
    <div [hidden]="activeText !== 2">
        <svg viewBox="0 0 600 100" (click)="changeText()" id="gradient-text">
            <text text-anchor="middle"
                  x="50%"
                  y="50%"
                  dy=".35em"
                  class="text"
                  fill="url(#gr-simple)">
                Andrew Koroluk
            </text>
            <linearGradient id="gr-simple" x1="0" y1="0" x2="100%" y2="100%">
                <stop stop-color="hsl(200, 100%, 70%)" offset="10%"></stop>
                <stop stop-color="hsl(320, 100%, 50%)" offset="90%"></stop>
            </linearGradient>
        </svg>
    </div>
    <div [hidden]="activeText !== 3">
        <svg viewBox="0 0 600 100" (click)="changeText()" id="pattern-text">
            <text text-anchor="middle"
                  x="50%"
                  y="50%"
                  dy=".35em"
                  class="patterned-text">
                Andrew Koroluk
            </text>
            <pattern
                id="p-spots"
                viewBox="0 0 80 80"
                patternUnits="userSpaceOnUse"
                width="60" height="60"
                x="5" y="5">
                <g class="g-spots">
                    <circle r="5" cx="10" cy="10"></circle>
                    <circle r="7" cx="30" cy="30"></circle>
                    <circle r="5" cx="50" cy="10"></circle>
                    <circle r="9" cx="70" cy="30"></circle>

                    <circle r="11" cx="50" cy="50"></circle>
                    <circle r="5" cx="10" cy="50"></circle>
                    <circle r="7" cx="30" cy="70"></circle>
                    <circle r="9" cx="70" cy="70"></circle>
                </g>
            </pattern>
        </svg>
    </div>
    <div [hidden]="true">
        <svg viewBox="0 0 600 100" (click)="changeText()" id="diag-striped-text">
            <!-- Symbol with text -->
            <symbol id="s-text">
                <text text-anchor="middle"
                      x="50%" y="50%" dy=".35em"
                      class="text--line"
                >
                    Andrew Koroluk
                </text>
            </symbol>
            <clippath id="cp-text">
                <text text-anchor="middle"
                      x="50%" y="50%"
                      dy=".35em"
                      class="text--line">
                    Andrew Koroluk
                </text>
            </clippath>
            <g clip-path="url(#cp-text)">
                <circle r="70%"
                        cx="300"
                        cy="150"
                        class="c-fill--color">
                </circle>
                <circle r="70%"
                        cx="300"
                        cy="150"
                        class="c-fill">
                </circle>
            </g>
            <pattern
                id="p-lines"
                width="40"
                height="50"
                viewBox="0 0 40 50"
                patternUnits="userSpaceOnUse">
                <rect
                    width="100%"
                    height="100%"
                    fill="hsl(199, 98%, 48%)"></rect>

                <g stroke="rgba(255,255,255,.4)" stroke-width="20">
                    <path d="M25,0 25,100"></path>
                </g>
            </pattern>

            <!-- Symbol for stroke -->
            <use xlink:href="#s-text" class="text-copy-stroke"></use>

            <!-- Group with clippath -->
            <g clip-path="url(#cp-text)">
                <!-- Content for filling text -->
                <circle r="70%"
                        cx="300"
                        cy="150"
                        class="c-fill--color">
                </circle>
                <circle r="70%"
                        cx="300"
                        cy="150"
                        class="c-fill">
                </circle>
            </g>
        </svg>
    </div>
</div>

<div class="vendor-section-v2">
    <h1 class="page-header">Powered by:</h1>
    <div class="columns" #columns>
        <div *ngFor="let col of cols" class="column">
            <div *ngFor="let image of col" class="">
                <a href="{{image.href}}">
                    <img src="{{image.src}}" alt="{{image.alt}}">
                </a>
            </div>
        </div>
    </div>
</div>
