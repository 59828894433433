import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {ToolboxConfig} from '../../../server/api/toolbox/util';

const TOOLBOX_URL = '/api/toolbox';

@Injectable()
export class DevToolboxService {
    constructor(private readonly http: HttpClient) {}

    getConfig() {
        return firstValueFrom(this.http.get<ToolboxConfig>(TOOLBOX_URL));
    }

    setConfig(config: ToolboxConfig) {
        return firstValueFrom(this.http.post(TOOLBOX_URL, config, {responseType: 'text'}));
    }
}
