import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SignupInfo} from './auth.service';
import {firstValueFrom} from 'rxjs';

export interface User {
    // TODO: use Mongoose model
    _id?: string;
    id?: string;
    name?: string;
    email?: string;
    password?: string;
    role?: string;
    imageId: string;
    smallImageId?: string;
}

function handleError(err) {
    throw err;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private readonly http: HttpClient) {}

    query() {
        return firstValueFrom(this.http.get('/api/users/'))
            .catch(handleError);
    }
    get(user?: User): Promise<User&{token: string}> {
        return firstValueFrom(this.http.get(`/api/users/${user ? user.id : 'me'}`))
            .catch(handleError) as Promise<User&{token: string}>;
    }
    create(user: SignupInfo): Promise<{token: string}> {
        return firstValueFrom(this.http.post('/api/users/', user))
            .catch(handleError) as Promise<{token: string}>;
    }
    changePassword(id: string, oldPassword: string, newPassword: string): Promise<User> {
        return firstValueFrom(this.http.put(`/api/users/${id}/password`, {oldPassword, newPassword}))
            .catch(handleError) as Promise<User>;
    }
    remove(user: User): Promise<User> {
        return firstValueFrom(this.http.delete(`/api/users/${user.id}`))
            .catch(handleError) as Promise<User>;
    }
}
