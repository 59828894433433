import {Component} from '@angular/core';

@Component({
    selector: 'admin',
    templateUrl: './admin.html',
})
export class AdminComponent {
    sections = [{
        title: 'Home',
        icon: 'home',
        link: '/admin',
    }, {
        title: 'Users',
        icon: 'person',
        link: 'users',
    }, {
        title: 'Galleries',
        icon: 'collections',
        link: 'galleries',
    }, {
        title: 'Projects',
        icon: 'work',
        link: 'projects',
    }, {
        title: 'Blog',
        icon: 'feed',
        link: 'blog',
    }, {
        title: 'Files',
        icon: 'source',
        link: 'files',
    }, {
        title: 'Settings',
        icon: 'settings',
        link: 'settings',
    }];
}
